.headerCc {
  background: #155b98;
  display: flex;
  justify-content: center;
  .navBar {
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    @media (max-width: 991px) {
      padding-bottom: 10px;
    }
  }
  .toggler {
    display: none;
    font-size: 4rem;
    color: #fff;
    @media (max-width: 991px) {
      display: block;
    }
  }
  .logo {
    width: 238.672px;
    height: 62.595px;
    @media (max-width: 991px) {
      width: 150px;
    }
  }
  .headerContainer {
    width: 100%;
    max-width: 1920px;
    padding: 40px 70px 50px;
    @media (max-width: 991px) {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 10px;
    }
  }
  .navLink {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    @media (max-width: 991px) {
      text-align: center;
      font-size: 2rem;
    }
    @media (min-width: 991px) and (max-width: 1194px) {
      font-size: 1rem;
    }
  }
  .navLinkButton {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
    text-align: left;
    text-transform: none;
    color: #fff;
    padding-left: 20px;
  }
  .navLinkSpan {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.875rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2.25rem;
    text-align: left;
    text-transform: none;
    color: #fff;
    padding-left: 20px;
  }
  .ourServiceTitle {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 10rem;
    font-weight: bold;
    line-height: 6rem;
    text-align: left;
    text-transform: none;
    letter-spacing: 10px;
    color: #fff;
    padding-right: 580px;
    @media (min-width: 1023px) and (max-width: 1199px) {
      font-size: 8rem !important;
      padding: unset;
      text-align: center;
    }
    @media (min-width: 1200px) and (max-width: 1479px) {
      font-size: 8rem !important;
      padding: unset;
      text-align: left;
    }
    @media (max-width: 725px) {
      font-size: 6rem !important;
      padding: unset;
      text-align: center;
    }
    @media (max-width: 425px) {
      font-size: 4rem !important;
      padding: unset;
    }
  }
  .ourServiceSubTitle {
    margin-top: 30px;
    width: 170px;
    @media (max-width: 1280px) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
    }
  }
  .ourServiceDetail {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.625rem;

    font-style: normal;
    letter-spacing: 0;
    line-height: 1.625rem;
    text-transform: none;
    color: #fff;
    padding-bottom: 30px;
    @media (max-width: 991px) {
      text-align: center;
    }
  }
}

.navItem {
  padding-right: 50px;
  &:last-child {
    padding-right: unset;
  }
  @media (max-width: 991px) {
    padding-right: unset;
    padding-bottom: 20px;
  }
  @media (min-width: 991px) and (max-width: 1381px) {
    padding-right: unset;
  }
}
.detailServiceText {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: center;
  }
}

.headerCcNav {
  background: #155b98;
  display: flex;
  justify-content: center;
  .navBarNav {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    @media (max-width: 991px) {
      padding-bottom: 5px;
    }
  }
  .togglerNav {
    display: none;
    font-size: 4rem;
    color: #fff;
    @media (max-width: 991px) {
      display: block;
    }
  }
  .logoNav {
    width: 238.672px;
    height: 62.595px;
    @media (max-width: 991px) {
      width: 150px;
    }
  }
  .headerContainerNav {
    width: 100%;
    max-width: 1920px;
    padding: 10px 70px 10px;
    @media (max-width: 991px) {
      padding: 5px 20px;
    }
  }
  .navLinkNav {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    @media (max-width: 991px) {
      text-align: center;
      font-size: 2rem;
    }
    @media (min-width: 991px) and (max-width: 1194px) {
      font-size: 1rem;
    }
  }
  .navLinkButtonNav {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.375rem;
    text-align: left;
    text-transform: none;
    color: #fff;
    padding-left: 20px;
  }
  .navLinkSpanNav {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 1.875rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 2.25rem;
    text-align: left;
    text-transform: none;
    color: #fff;
    padding-left: 20px;
  }
}
.infoDetail {
  display: flex;
  align-items: center;
  justify-content: right;

  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.textBoldedInfo {
  font-family: "Barlow", sans-serif;
  font-size: 1.75rem;
  font-weight: bolder;
  letter-spacing: 2.5px;
  font-style: normal;
  line-height: 1.75rem;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
}

.textBoldInfo {
  font-family: "Barlow", sans-serif;
  font-size: 0.75rem;
  font-weight: bolder;
  letter-spacing: 0;
  font-style: normal;
  line-height: 0.875rem;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
}
.iconArrow {
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
}
