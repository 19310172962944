.headerCc {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  background: #155b98;
  @media (max-width: 991px) {
    margin-top: 50px;
  }
}
.footerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding: 20px 70px 20px;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.navItem {
  padding-right: 30px;
  &:last-child {
    padding-right: unset;
  }
  @media (max-width: 991px) {
    padding-right: unset;
    padding-bottom: 20px;
  }
}
.navLink {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1rem !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.188rem;
  text-align: left;
  text-transform: uppercase;
  color: #fff !important;
  @media (max-width: 991px) {
    text-align: center;
    font-size: 3rem !important;
  }
}
.infoDetail {
  display: flex;
  align-items: center;
  justify-content: right;

  @media (max-width: 991px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.text {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.188rem;
  text-align: right;
  color: #fff;
}
.textBold {
  font-weight: bold;
  cursor: pointer;
}
.textNormal {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.188rem;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  @media (max-width: 991px) {
    text-align: center;
  }
}
.iconsFooter {
  display: flex;
  padding-top: 20px;
  @media (max-width: 991px) {
    width: 100%;
    justify-content: center;
  }
}
.textBoldedInfo {
  font-family: "Barlow", sans-serif;
  font-size: 3.75rem;
  font-weight: bolder;
  letter-spacing: 0;
  font-style: normal;
  line-height: 3rem;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
}

.textBoldInfo {
  font-family: "Barlow", sans-serif;
  font-size: 1.35rem;
  font-weight: bolder;
  letter-spacing: 0.7px;
  font-style: normal;
  line-height: 0.875rem;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
}
.footerRow {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 991px) {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.borderTopFooter {
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.followAsFooter {
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
  }
}
