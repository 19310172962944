.headerCc {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  @media (max-width: 991px) {
    padding-top: 50px;
  }
}
.locationContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding-left: 70px;
  padding-right: 70px;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.locationTitle {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 3.5rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 4.2rem;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  padding-right: 20px;
  span {
    color: #da212e;
  }
  @media (min-width: 1200px) and (max-width: 1900px) {
    font-size: 2.5rem;
  }
  @media (max-width: 1200px) {
    font-size: 3rem;
  }
}

.hr {
  margin-right: auto;
  width: 68%;
  height: 1px;
  padding: 1px;
  background: #9f9f9f;
}
.locationMap {
  position: relative;
  margin-top: 50px;
  width: 100%;
  height: 642px;
  @media (max-width: 991px) {
    height: 300px;
    background-size: 100% 100%;
  }
}

.questions {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #fff;
  span {
    margin-left: 10px;
  }
}

.questionTitle {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.75rem;
  text-align: left;
  text-transform: uppercase;
  color: #000;
  text-wrap: nowrap;
  padding-right: 20px;
  @media (min-width: 1200px) and (max-width: 1900px) {
    font-size: 3.5rem;
  }
}
