.headerCc {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  @media (max-width: 991px) {
    padding-top: 50px;
  }
}
.packetContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding-left: 70px;
  padding-right: 70px;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.packetTitle {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 4.375rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 5.25rem;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  padding-right: 20px;
  span {
    color: #da212e;
  }
  @media (min-width: 1200px) and (max-width: 1900px) {
    font-size: 3.5rem;
  }
}

.hr {
  margin-left: auto;
  width: 65%;
  height: 1px;
  padding: 1px;
  background: #9f9f9f;
}
.packetDetail {
  padding-top: 70px;
}
.packetImage {
  width: 100%;
  height: 570px;
  background: url("../../../public/image-step.png") no-repeat;
  @media (max-width: 1280px) {
    max-width: 350px;
    height: 370px;
    background-size: 100% 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.step {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 6.25rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 7.5rem;
  text-align: left;
  text-transform: uppercase;
  color: #da212e;
  text-wrap: nowrap;
  padding-right: 20px;
}
.stepTitle {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: left;
  color: #000;
  padding-right: 20px;
  span {
    color: #da212e;
    font-weight: bold;
  }
  @media (max-width: 991px) {
    text-align: center;
    padding-right: unset;
  }
}
.stepDetail {
  font-family: "Barlow ", sans-serif;
  font-size: 1.125rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.313rem;
  text-align: left;
  color: #000;
  padding-right: 20px;
}

.textBold {
  font-weight: bold;
}
.stepCol {
  display: flex;
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding-left: 20px;
  @media (max-width: 991px) {
    padding: 20px;
  }
}
.setButtonTextBtn {
  font-family: "Barlow ", sans-serif;
  font-size: 1.25rem;
  font-weight: bolder;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.313rem;
  text-align: left;
  color: #fff;
}
.setButtonText {
  font-family: "Barlow ", sans-serif;
  font-size: 1.75rem;
  font-weight: bolder;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.313rem;
  text-align: left;
  color: #fff;
  padding: 15px;
}

.icons {
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}
.iconText {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.188rem;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  text-wrap: wrap;
  padding: 10px;
  @media (max-width: 991px) {
    text-align: center;
  }
}
.icon {
  width: 100%;
  height: 98px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: #da212e;
  }
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
  }
  &:hover {
    background-color: #da212e;
    svg {
      fill: #fff;
    }
  }
}
.iconDetails {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;

  div {
    div {
      &:hover {
        background-color: #da212e;

        svg {
          fill: #fff;
        }
      }
    }
  }
}
