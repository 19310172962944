.headerCc {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  @media (max-width: 991px) {
    padding-top: 50px;
  }
}
.packetWidthContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding-left: 70px;
  padding-right: 70px;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.prevBtn {
  padding-right: 30px;
  cursor: pointer;
  &:hover {
    color: #da212e;

    transform: scale(1.1);
  }
}

.nextBtn {
  padding-left: 30px;
  &:hover {
    cursor: pointer;
    color: #da212e;
    transform: scale(1.1);
  }
}
.pdfView {
  width: 100% !important;
  @media (max-width: 375px) {
    transform: scale(0.6);
  }
}
