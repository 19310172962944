.headerCc {
  display: flex;
  justify-content: center;
}
.questionContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.questionTitle {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 5rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 6rem;
  text-align: left;
  text-transform: uppercase;
  color: #000;
  text-wrap: nowrap;
  padding-right: 20px;
  @media (min-width: 1200px) and (max-width: 1900px) {
    font-size: 3.5rem;
  }
}

.hr {
  margin-right: auto;
  width: 68%;
  height: 1px;
  padding: 1px;
  background: #9f9f9f;
  @media (min-width: 1200px) and (max-width: 1900px) {
    width: 60%;
  }
}

.questionImage {
  background: url("../../../public/ee-question.png");
  width: 100%;

  height: 326px;
  border-radius: 10px;
  background-size: 100% 100%;
}
.questions {
  padding-left: 20px !important;
  .question {
    padding-top: 25px;
    padding-bottom: 30px;
    border-bottom: 1px solid #9f9f9f;
    .questionName {
      display: flex;
      align-items: center;
      .questionNameText {
        font-family: "Barlow Condensed", sans-serif;
        font-size: 2.188rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 2.188rem;
        text-align: left;
        color: #000;
        text-wrap: nowrap;
        padding-left: 30px;
      }
    }

    .questionDetail {
      .questionDetailText {
        color: #9f9f9f;
        font-family: "Barlow Condensed", sans-serif;
        font-size: 1.25rem;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0;
        line-height: 2.188rem;
        text-align: left;
      }
    }
  }
}

.questionsDetail {
}
