.headerCc {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.videoContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding: 30px 50px;
  @media (max-width: 991px) {
    padding: 20px 30px;
  }
  @media (max-width: 725px) {
    padding: 10px 20px;
  }
  background-color: #f0f0f0;
  .video {
    width: 100%;
    height: 907px;
    background: url("../../../public/video-image.png");
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 991px) {
      height: 507px;
      background-size: 100% 100%;
    }
    @media (max-width: 725px) {
      height: 307px;
      background-size: 100% 100%;
    }
    .playButton {
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(0.95);
      }
      @media (max-width: 991px) {
        svg {
          width: 100px;
        }
      }
    }
  }
}
.icons {
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
  @media (max-width: 991px) {
    padding-top: 20px;
  }
}
.iconText {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.188rem;
  text-align: left;
  text-transform: uppercase;
  color: #000;
  padding-top: 20px;
  text-wrap: wrap;
  @media (max-width: 991px) {
    text-align: center;
  }
  @media (max-width: 725px) {
    font-size: 1.475rem;
  }
}
.icon {
  width: 98px;
  height: 98px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  svg {
    fill: #da212e;
  }
  @media (max-width: 991px) {
    margin-left: auto;
    margin-right: auto;
  }
  &:hover {
    background-color: #da212e;
    svg {
      fill: #fff;
    }
  }
}
.iconDetails {
  display: flex;
  flex-wrap: wrap;
  user-select: none;
}
.iconDetailsCol {
}

.videoPlayer {
  width: 100%;
  height: 500px;
  @media (min-width: 991px) {
    height: 600px;
  }
}
