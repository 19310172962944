.locationName {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.25rem;
  text-transform: uppercase;
  color: #000;
  text-align: center;
}

.locationAddress {
  padding-top: 5px;

  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #000;
  padding-right: 20px;
  span {
    // margin-left: 10px;
  }
}

.locationInfo {
  padding-top: 5px;

  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #000;
  padding-right: 20px;
  span {
    //margin-left: 10px;
  }
}
