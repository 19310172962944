.headerCc {
  display: flex;
  justify-content: center;
  padding-top: 100px;
  @media (max-width: 991px) {
    padding-top: 50px;
  }
}
.packetWidthContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  width: 100%;
  padding-left: 70px;
  padding-right: 70px;
  @media (max-width: 991px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.packetWidthTitle {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 4.375rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 5.25rem;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  padding-right: 20px;
  span {
    color: #da212e;
  }
  @media (min-width: 1200px) and (max-width: 1900px) {
    font-size: 3.5rem;
  }
}
.packetWidthDetail {
  padding: 20px;
}
.packetWidthDetailTitle {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 2.188rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.188rem;
  text-align: left;
  text-transform: uppercase;
  color: #000;
  padding-bottom: 10px;
}
.packetWidthDetailSubTitle {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.188rem;
  text-align: left;
  text-transform: uppercase;
  color: #9f9f9f;
}
.packetWidthDetails {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
}
.borderBottom {
  border-bottom: 1px solid #9f9f9f;
}
.borderTop {
  border-top: 1px solid #9f9f9f;
}
.packetWidthImage {
  border-radius: 10px;
}
.imageS {
  margin-top: 20px;
  background: url("../../../public/S.jpg");
  width: 100%;
  height: 533px;
  background-size: 100% 100%;
  @media (max-width: 1280px) {
    height: 333px;
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
  }
}
.imageM {
  background: url("../../../public/M.jpg");
  width: 100%;
  height: 533px;
  background-size: 100% 100%;
  margin-bottom: 20px;
  @media (max-width: 1280px) {
    height: 333px;
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
  }
}
.imageL {
  margin-top: 20px;
  background: url("../../../public/L.jpg");
  width: 100%;
  height: 533px;
  background-size: 100% 100%;
  @media (max-width: 1280px) {
    height: 333px;
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
  }
}
.showDesktop {
  @media (max-width: 991px) {
    display: none;
  }
  @media (min-width: 1024px) {
    display: flex;
  }
}
.showMobile {
  @media (max-width: 991px) {
    display: flex;
  }
  @media (min-width: 1024px) {
    display: none;
  }
}
